import React from "react"
import Header from "../../components/header"
import Footer from "../../components/footer"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import ContactBox from "../../components/boxContact"

import imgBegruenung01 from "../../assets/leistungen/gruen01.jpg"
import imgBegruenung02 from "../../assets/leistungen/gruen02.jpg"
import imgBegruenung03 from "../../assets/leistungen/gruen03.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Dachbegrünung"
      Content="Dächer sind nicht nur Schutz vor den Naturgewalten, sondern bieten vielen Lebewesen einen wertvollen Lebensraum. Dies gilt insbesondere dann, wenn eine Dachbegrünung angelegt ist. Bei flachen Dächern steht dem Bewohner selbst ein attraktiver Raum zum Leben zur Verfügung. Die Broschüre zeigt, wie Dachterrassen, begrünte Dächer, Balkone und andere individuelle Lösungen die Lebensqualität verbessern. Sprechen Sie mit Ihren Kunden darüber!"
    />
    <LeistungBox
      Color="gray"
      Image={imgBegruenung01}
      IsImageLeft={true}
      Title="Extensive Dachbegrünung"
      Content="Die extensive Dachbegrünung sieht beinahe aus wie eine natürliche Grünfläche. Dafür kommen niedrigwüchsige Pflanzen wie Moose, Gräser oder Sukkulenten zum Einsatz. Durch diese Pflanzenwahl hält sich einerseits der Pflegeaufwand in Grenzen. Zum anderen beschränkt sich dadurch die Höhe des Gründach-Schichtaufbaus auf etwa fünf bis 15 Zentimeter; das Gewicht liegt bei ca. 50 bis 170 Kilogramm pro Quadratmeter. Extensive Dachbegrünungen werden vor allem als ökologische Ausgleichsflächen für überbaute Natur angelegt."
    />
    <LeistungBox
      Color="white"
      Image={imgBegruenung02}
      IsImageLeft={false}
      Title="Intensive Dachbegrünung"
      Content="Mit dem Begriff „Intensive Dachbegrünung“ ist ein Dachgarten gemeint, der durchaus mit ebenerdigen Gärten vergleichbar ist und mit Rasenflächen, Stauden, Gehölzen oder sogar Bäumen gestaltet werden kann. Je nach Wahl der Pflanzen ist der Gesamtaufbau dieser Begrünungen unterschiedlich hoch. Während bei niedrigwüchsigen Pflanzen die Höhe etwa 25 Zentimeter hoch ist, wird bei parkähnlicher Gestaltung, etwa auf Tiefgaragen, eine Höhe von bis zu einem Meter erreicht. Das Gewicht orientiert sich an der Ausführung und bewegt sich zwischen 300 und 1.200 Kilogramm pro Quadratmeter. Der Pflegeaufwand ist bei intensiven Dachbegrünungen recht hoch: Mehrmals jährlich sollten diese Flächen gepflegt und gewartet werden. Doch die Mühe lohnt sich: Werden diese Dachgärten doch oftmals als zusätzlicher Wohnraum genutzt."
    />
    <LeistungBox
      Color="gray"
      Image={imgBegruenung03}
      IsImageLeft={true}
      Title="Begrünung von Garagen &amp; Carports"
      Content="Garagen- und Carportdächer eignen sich ebenfalls für eine Dachbegrünung – vorausgesetzt die statischen und abdichtungstechnischen Bedingungen werden erfüllt. Wichtig sind hierbei die fachgerechte Planung und die Verwendung geeigneter Abdichtungsbahnen"
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
